<ion-card>
  <ion-card-header>
    <ion-button
      class="button-detail"
      size="small"
      color="light"
      shape="round"
      @click="goToDetail"
      v-if="withDetail"
    >
      Dirty Details
    </ion-button>
    <ion-card-title v-if="title">{{ title }}</ion-card-title>
    <ion-card-subtitle v-if="subtitle">{{ subtitle }}</ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-img v-if="imgUrl" :src="imgUrl" class="ion-padding-bottom"></ion-img>
    <ion-text v-if="text">{{ text }}</ion-text>
    <hr />
    <ion-text v-if="date">Completed: {{ date }}</ion-text>
    <ion-buttons v-if="isAuthenticated && withActions">
      <ion-button size="small" @click="editEntry">
        <ion-icon :icon="pencil" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button size="small" @click="deleteEntry">
        <ion-icon :icon="trash" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-card-content>
</ion-card>
