<ion-header :translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button v-if="!isDetail && isMobile"></ion-menu-button>
      <ion-back-button
        v-if="isDetail"
        :default-href="defaultHref"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span v-if="isMobile">
        {{ title }}
      </span>
      <span v-if="!isMobile">
        <span v-if="name">
          {{ name }}
        </span>
        <ion-spinner v-if="!name"></ion-spinner>
      </span>
    </ion-title>
    <ion-buttons slot="end">
      <span v-if="!isMobile">
        <router-link
          :to="{name: 'home'}"
          custom
          v-slot="{ href, isActive }"
          active-class="active"
        >
          <ion-button :class="{ active: isActive }" :router-link="href">
            Home
          </ion-button>
        </router-link>
        <router-link
          :to="{name: 'learning'}"
          custom
          v-slot="{ href, isActive }"
          active-class="active"
        >
          <ion-button :class="{ active: isActive }" :router-link="href">
            Learning
          </ion-button>
        </router-link>
        <router-link
          :to="{name: 'projects'}"
          custom
          v-slot="{ href, isActive }"
          active-class="active"
        >
          <ion-button :class="{ active: isActive }" :router-link="href">
            Projects
          </ion-button>
        </router-link>
      </span>
      <ion-button @click="openDropdown">
        <ion-icon
          :icon="isPlatform('ios') ? ellipsisHorizontal : ellipsisVertical"
          slot="icon-only"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
