<ion-page>
  <app-header title="Home"></app-header>
  <ion-content :fullscreen="true">
    <ion-grid v-if="aboutData">
      <ion-row>
        <ion-col size="3" size-md="1" offset="9" offset-md="11">
          <ion-buttons v-if="isAuthenticated">
            <ion-button size="small" @click="openForm">
              <ion-icon :icon="pencil" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-md="8" offset-md="2">
          <app-card
            :title="aboutData.title"
            :imgUrl="aboutData.profileImgUrl"
            :text="aboutData.headline"
          ></app-card>
          <ion-row>
            <ion-col>
              <ion-button
                color="primary"
                expand="block"
                :href="aboutData.resumeUrl"
                target="_blank"
              >
                <ion-icon :icon="cloudDownload"></ion-icon>
                &nbsp;Resume
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                color="secondary"
                expand="block"
                :href="aboutData.linkedInUrl"
                target="_blank"
              >
                <ion-icon :icon="logoLinkedin"></ion-icon>
                &nbsp;LinkedIn
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="12" size-md="8" offset-md="2">
          <app-card
            :title="'Life Story'"
            :subtitle="'Short Version'"
            :text="aboutData.lifeStory"
          ></app-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ion-page>
